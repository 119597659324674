import 'bootstrap/js/dist/util';
import 'bootstrap/js/dist/dropdown';
// import 'bootstrap/js/dist/button';
// import 'bootstrap/js/dist/carousel';
// import 'bootstrap/js/dist/popover';
// import 'bootstrap/js/dist/scrollspy';


let tooltips = document.querySelectorAll('[data-toggle="tooltip"]');
if(tooltips.length) {
    new Promise(() => {
        import(/* webpackChunkName: "tooltip" */ 'bootstrap/js/dist/tooltip')
        .then((Tooltip) => {
            for(var i = 0; i < tooltips.length; i++)
            {
                let tooltip = tooltips.item(i);
                new Tooltip.default(tooltip).tooltip();
            } 
        })
    }) 
}

let modals = document.querySelectorAll('[data-toggle="modal"]');
if(modals.length) {
    new Promise(() => {
        import(/* webpackChunkName: "modal" */ 'bootstrap/js/dist/modal')
        .then(() => {
            console.log('modals found')
        })
    }) 
}

let tabs = document.querySelectorAll('[data-toggle="tab"]');
if(tabs.length) {
    new Promise(() => {
        import(/* webpackChunkName: "tab" */ 'bootstrap/js/dist/tab')
        .then(() => {
            console.log('tabs found')
        })
    }) 
}

let alerts = document.querySelectorAll('[data-dismiss="alert"]');
if(alerts.length) {
    new Promise(() => {
        import(/* webpackChunkName: "alert" */ 'bootstrap/js/dist/alert')
        .then(() => {
            console.log('alerts found')
        })
    }) 
}

let collapses = document.querySelectorAll('.collapse');
if(collapses.length) {
    new Promise(() => {
        import(/* webpackChunkName: "collapse" */ 'bootstrap/js/dist/collapse')
        .then(() => {
            console.log('collapse found')
        })
    }) 
}

let toasts = document.querySelectorAll('.toast');
if(toasts.length) {
    new Promise(() => {
        import(/* webpackChunkName: "toast" */ 'bootstrap/js/dist/toast')
        .then(() => {
            for(var i = 0; i < toasts.length; i++)
            {
                let toast = toasts.item(i);
                $(toast).toast('show');
            } 
        })
    }) 
}


