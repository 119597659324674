import { library, dom, config } from '@fortawesome/fontawesome-svg-core';
import { faSearch as faSearchLight,  faEye, faGlobe, faTimes as faTimesLight, faFileWord, faFilePowerpoint, faFileImage, faFileSpreadsheet, faFileArchive, faFilePdf, faFile, faPlayCircle, faArrowUp, faEdit, faSpinner, faQuoteLeft, faQuoteRight, faUser as faUserLight, faCircle as faCircleLight } from '@fortawesome/pro-light-svg-icons';
import { faChevronRight as faChevronRightRegular, faChevronLeft as faChevronLeftRegular, faChevronUp as faChevronUpSolid, faSearch as faSearchRegular, faTimes as faTimesRegular, faCloudDownloadAlt as faCloudDownloadAltRegular, faCircle as faCircleRegular, faWaveform as faWaveformRegular, faAngleRight, faAngleLeft } from '@fortawesome/pro-regular-svg-icons';
import { faSearch as faSearchSolid, faLink, faDownload, faCircle, faSpinner as faSpinnerSolid, faHeart as faHeartSolid, faShareAlt as faShareAltSolid, faCaretRight as faCaretRightSolid } from '@fortawesome/pro-solid-svg-icons';
import { faFacebookF, faFacebookSquare, faTwitter, faYoutube, faTwitterSquare, faLinkedinIn, faLinkedin, faInstagram, faWhatsapp } from '@fortawesome/free-brands-svg-icons';

config.searchPseudoElements = true;
config.keepOriginalSource = false;

library.add(
	faAngleLeft,
	faAngleRight,
	faArrowUp,
	faCaretRightSolid,
	faChevronLeftRegular,
	faChevronRightRegular,
	faChevronUpSolid,
	faCircle,
	faCircleLight,
	faCircleRegular,
	faCloudDownloadAltRegular,
	faDownload,
	faEdit,
	faEye,
	faFacebookF,
	faFacebookSquare,
	faFile,
	faFileArchive,
	faFileImage, 
	faFilePdf, 
	faFilePowerpoint, 
	faFileSpreadsheet, 
	faFileWord,
	faGlobe,
	faHeartSolid,
	faInstagram,
	faLink,
	faLinkedinIn,
	faLinkedin,
	faPlayCircle,
	faQuoteLeft, 
	faQuoteRight,
	faSearchLight, 
	faSearchRegular,
	faSearchSolid,
	faShareAltSolid,
	faSpinner,
	faSpinnerSolid,
	faTimesLight,
	faTimesRegular,
	faTwitter,
	faTwitterSquare,	
	faUserLight,
	faWhatsapp,
	faWaveformRegular,
	faYoutube
);
dom.i2svg();
dom.watch();