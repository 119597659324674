import {} from '../css/main.scss';
import '../js/lazysizes';
import "../js/bootstrap";
import "../js/fontawesome";

import ExternalLinks  from "./externallinks";
import PageTimer from "./pagetimer";


    (new PageTimer()).logToConsole();
    (new ExternalLinks('body')).addLinkTarget();

    let glides = document.getElementsByClassName('glide');
    if (glides.length) {
        new Promise((resolve) => {
            import(/* webpackChunkName: "glider" */ '../js/glide').
                then((Glider) => {
                    let glide = new Glider.default(glides);
                    resolve(glide.initialize());
                });
        })
    }
 
    let popups = document.querySelectorAll('[data-mfp-type]');
    if(popups.length) {
        new Promise((resolve) => {
            import(/* webpackChunkName: "magnific" */ '../js/magnificpopup')
                .then((Magnific) => {
                    let magnific =  new Magnific.default(popups);
                    resolve(magnific.initialize())
                })
        })
    }

    let forms = document.getElementsByClassName('needs-validation');
    if(forms.length) {
        new Promise((resolve) => {
            import(/* webpackChunkName: "forms" */ '../js/form')
                .then((Forms) => {
                    let form =  new Forms.default(forms);
                    resolve(form.submitButton().validate())
                })
        })
    }


    let shares = document.querySelectorAll('[data-share-default]');
    if(shares.length) {
        new Promise((resolve) => {
            import(/* webpackChunkName: "share" */ '../js/share')
                .then((ShareHandler) => {
                    let socialShare =  new ShareHandler.default();
                    resolve(socialShare.initialize())
                })
        })
    }



// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
    module.hot.accept();
}

// Listen button on each episode entry page
$(function(){
    $('#play').on('click', function() {
        console.log('audio');
        // Toggle text
        $('.play').toggleClass('d-flex d-none');
        $('.pause').toggleClass('d-none d-flex');
        // Find audio elememt ID
        var audio = $('#audio');
        // Toggle active class 
        // Use active class to detect state of play
        audio.toggleClass('active');
        if(audio.hasClass('active')){
            audio[0].play();        
        } else {
            audio[0].pause();
        }
    });
});